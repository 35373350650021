@font-face {
    font-family: 'WeblySleek UI';
    src: url('weblysleek-ui-semibold.woff2') format('woff2'),
    url('weblysleek-ui-semibold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'WeblySleek UI';
    src: url('weblysleek-ui-semibold-italic.woff2') format('woff2'),
    url('weblysleek-ui-semibold-italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'WeblySleek UI';
    src: url('weblysleek-ui-regular.woff2') format('woff2'),
    url('weblysleek-ui-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'WeblySleek UI';
    src: url('weblysleek-ui-italic.woff2') format('woff2'),
    url('weblysleek-ui-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
